import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Alert,
  Typography,
  Stack,
  TablePagination,
  LinearProgress
} from "@mui/material";
import { useEffect, useState } from "react";
import { CustomersBankAccount, CustomersBankTransaction } from "./types";
import axios from "axios";
import { fDate, fDateTime, fTime } from "../../utils/formatTime";
import { fFullCurrency } from "../../utils/formatNumber";
import CustomersBankLogo from "./CustomersBankLogo";
import Page from "../../components/Page";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import useLocalStorage from "../../hooks/useLocalStorage";
import Filters from "./Filters";
import useDebounce from "../../hooks/useDebounce";
import { ListHead } from "../../list";

const codeDescriptionList = [
  "ACH DEPOSIT",
  "ACH WITHDRAWAL",
  "AUTOMATIC TRANSFER",
  "BOLB OUTGOING WIRE",
  "CHECK",
  "COMM'L SCANNED DEP",
  "EBANKING TRANSFER",
  "INCOMING WIRE",
  "INTEREST CREDIT",
  "INTEREST TRANSFER",
  "OUTGOING WIRE",
  "WITHDRAWAL"
];

const TABLE_HEAD = [
  { id: "PostingDate", label: "Posting Date", alignRight: false },
  { id: "AccountNumber", label: "Account", alignRight: false },
  { id: "Amount", label: "Amount", alignRight: false },
  { id: "TransactionType", label: "Type", alignRight: false },
  { id: "Description", label: "Description", alignRight: false },
  { id: "TransactionCode", label: "Code", alignRight: false },
  { id: "TransactionCodeDescription", label: "Code Description", alignRight: false },
  { id: "EffectiveDate", label: "Effective Date", alignRight: false },
  { id: "ControlNumber", label: "Control Number", alignRight: false }
];

const CustomersBankTransactions = () => {
  const [transactions, setTransactions] = useState<CustomersBankTransaction[]>([]);
  const [accounts, setAccounts] = useState<CustomersBankAccount[]>([]);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [bankAccountList, setBankAccountList] = useState<string[]>([]);
  const [selectedBankAccounts, setSelectedBankAccounts] = useLocalStorage<string[]>("bankAccounts", []);
  const [selectedCodeDescriptions, setSelectedCodeDescriptions] = useLocalStorage<string[]>("codeDescriptions", []);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState("PostingDate");
  const [totalResults, setTotalResults] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [totalAvailableBalance, setTotalAvailableBalance] = useState<number | null>(null);
  const [selectedAccountsAvailableBalance, setSelectedAccountsTotalAvailableBalance] = useState<number | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

  const getAndSetAccounts = async () => {
    try {
      const { data } = await axios.get<CustomersBankAccount[]>("/customersbanktransaction/getaccounts");
      setAccounts(data);
      var updatedDate = data[0].updatedDateTime;
      setLastUpdated(updatedDate);
      setBankAccountList(data.map((m) => m.accountNumber));
      var aggregatedBalance = data.reduce((acc, d) => acc + d.availableBalance, 0);
      setTotalAvailableBalance(aggregatedBalance);
    } catch (err) {
      setError("Failed to fetch accounts");
    } finally {
      setLoadingAccounts(false);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterBySearchText = (searchText: string) => {
    setSearchText(searchText);
    setPage(0);
  };

  const getParams = () => {
    let [fromDate, toDate] = dateRange;
    let toDateNew = toDate?.add(1, "day");
    const formatDate = (d: Dayjs) => d.format("MM/DD/YYYY");
    return {
      queryText: debouncedSearchText.trim(),
      page,
      rowsPerPage,
      orderBy,
      descending: order === "desc",
      bankAccounts: selectedBankAccounts,
      type: selectedType,
      codeDescriptions: selectedCodeDescriptions,
      dateStart: fromDate ? formatDate(fromDate) : "",
      dateEnd: toDateNew ? formatDate(toDateNew) : ""
    };
  };

  const getAndSetTransactions = async () => {
    setLoadingTransactions(true);
    getTransactions(getParams());
  };

  const getTransactions = async (params: any) => {
    const qs = require("qs");
    const { data } = await axios({
      method: "get",
      url: `/customersbanktransaction/searchtransactions`,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    });
    setTransactions(data.transactions);
    setTotalResults(data.count);
    setLoadingTransactions(false);
  };

  useEffect(() => {
    getAndSetAccounts();
    getAndSetTransactions();
  }, []);

  useEffect(() => {
    getAndSetTransactions();
  }, [
    page,
    debouncedSearchText,
    rowsPerPage,
    orderBy,
    order,
    selectedBankAccounts,
    selectedType,
    dateRange,
    selectedCodeDescriptions
  ]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  useEffect(() => {
    var aggregatedBalances = selectedBankAccounts.reduce((acc: number, a: string) => {
      var account = accounts.find((account) => account.accountNumber === a);
      return acc + (account?.availableBalance || 0);
    }, 0);
    setSelectedAccountsTotalAvailableBalance(aggregatedBalances);
  }, [selectedBankAccounts, accounts]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Page title="Customers Bank" padding={3} position="relative">
      <Stack direction="column" marginLeft={2} spacing={0.4}>
        <CustomersBankLogo />
        <Typography variant="caption">Last updated: {lastUpdated && fDateTime(lastUpdated)}</Typography>
      </Stack>
      <Stack direction="column" spacing={2} marginBottom={2}>
        <Stack marginLeft={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2}>
            {selectedBankAccounts.length === 0 && <Typography variant="h4">Madison Title Agency</Typography>}
            {selectedBankAccounts.map((a: string) => {
              var account = accounts.find((account) => account.accountNumber === a);
              return (
                <Box textAlign="center">
                  <Typography variant="subtitle1">{account?.name}</Typography>
                  <Typography variant="subtitle1">
                    <Typography variant="body1" display="inline-block" marginRight={1}>
                      Available Balance:
                    </Typography>
                    {account?.availableBalance ? fFullCurrency(account?.availableBalance) : "--"}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
          {selectedBankAccounts.length === 0 && totalAvailableBalance !== null && (
            <Box textAlign="center">
              <Typography variant="h4">{fFullCurrency(totalAvailableBalance)}</Typography>
              <Typography variant="body1">Available Balance</Typography>
            </Box>
          )}
          {selectedBankAccounts.length > 0 && selectedAccountsAvailableBalance !== null && (
            <Box textAlign="center">
              <Typography variant="h4">{fFullCurrency(selectedAccountsAvailableBalance)}</Typography>
              <Typography variant="body1">Available Balance</Typography>
            </Box>
          )}
        </Stack>
        <Filters
          searchText={searchText}
          handleFilterBySearchText={handleFilterBySearchText}
          dateRange={dateRange}
          setDateRange={setDateRange}
          bankAccountList={bankAccountList}
          codeDescriptionList={codeDescriptionList}
          selectedBankAccounts={selectedBankAccounts}
          setSelectedBankAccounts={setSelectedBankAccounts}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedCodeDescriptions={selectedCodeDescriptions}
          setSelectedCodeDescriptions={setSelectedCodeDescriptions}
        />
      </Stack>
      <TableContainer component={Paper}>
        <Box sx={{ width: "100%", position: "relative" }}>
          {(loadingTransactions || loadingAccounts) && <LinearProgress sx={{ width: "100%", position: "absolute" }} />}
        </Box>
        <Table size="small">
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={0}
            numSelected={0}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow
                key={transaction.id}
                sx={{
                  borderBottom: "1px solid #212b361f",
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:first-of-type td, &:first-of-type th": {
                    border: 0
                  }
                }}
              >
                <TableCell>
                  <Typography variant="subtitle2">{fDate(transaction.postingDate)}</Typography>
                  <Typography variant="subtitle2" color="GrayText">
                    {fTime(transaction.postingDate)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{transaction.accountNumber}</Typography>
                </TableCell>
                <TableCell sx={{ color: transaction.transactionType === "D" ? "error.main" : "success.main" }}>
                  <Typography variant="body2">{fFullCurrency(transaction.amount)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{transaction.transactionType === "D" ? "Debit" : "Credit"}</Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: 300 }}>
                  <Typography variant="body2">{transaction.description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{transaction.transactionCode}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{transaction.transactionCodeDescription}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{fDate(transaction.effectiveDate)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{transaction.controlNumber}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[100, 150, 200]}
          component="div"
          count={totalResults}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Page>
  );
};

export default CustomersBankTransactions;
