import { Box, InputAdornment, TextField, Autocomplete, Checkbox, Grid } from "@mui/material";
import InputStyle from "../../components/InputStyle";
import Iconify from "../../components/Iconify";
import { DateRange, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import dayjs from "dayjs";
import { SetStateAction } from "react";

const lastMonth = dayjs().subtract(1, "month");

export default function Filters({
  searchText,
  handleFilterBySearchText,
  dateRange,
  setDateRange,
  bankAccountList,
  selectedBankAccounts,
  setSelectedBankAccounts,
  selectedType,
  setSelectedType,
  codeDescriptionList,
  selectedCodeDescriptions,
  setSelectedCodeDescriptions
}: {
  searchText: string;
  handleFilterBySearchText: (searchText: string) => void;
  dateRange: DateRange<dayjs.Dayjs>;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange<dayjs.Dayjs>>>;
  bankAccountList: string[];
  selectedBankAccounts: any;
  setSelectedBankAccounts: any;
  selectedType: string | null;
  setSelectedType: React.Dispatch<SetStateAction<string | null>>;
  codeDescriptionList: string[];
  selectedCodeDescriptions: any;
  setSelectedCodeDescriptions: any;
}) {
  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item lg={3} xs={12}>
        <InputStyle
          fullWidth
          value={searchText}
          onChange={(event) => handleFilterBySearchText(event.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={"eva:search-fill"} sx={{ color: "text.disabled", width: 20, height: 20 }} />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item md={3} xs={12} justifyContent="flex-start">
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: "Start Date", end: "End Date" }}>
          <DateRangePicker
            defaultCalendarMonth={lastMonth}
            calendars={2}
            value={dateRange}
            onChange={(newValue) => {
              setDateRange(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={2} xs={12}>
        <Autocomplete
          multiple
          limitTags={1}
          options={bankAccountList}
          value={selectedBankAccounts}
          getOptionLabel={(option) => option}
          onChange={(e, value) => setSelectedBankAccounts(value)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Bank Account" placeholder="Start typing..." sx={{ maxHeight: "100%" }} />
          )}
          fullWidth
        />
      </Grid>
      <Grid item lg={2} xs={12}>
        <Autocomplete
          options={["Credit", "Debit"]}
          value={selectedType}
          getOptionLabel={(option) => option}
          onChange={(e, value) => setSelectedType(value)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Type" placeholder="Start typing..." sx={{ maxHeight: "100%" }} />
          )}
          fullWidth
        />
      </Grid>
      <Grid item lg={2} xs={12}>
        <Autocomplete
          multiple
          limitTags={1}
          options={codeDescriptionList}
          value={selectedCodeDescriptions}
          getOptionLabel={(option) => option}
          onChange={(e, value) => setSelectedCodeDescriptions(value)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Code Description" placeholder="Start typing..." sx={{ maxHeight: "100%" }} />
          )}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
